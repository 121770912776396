import React, { useState, useEffect, useCallback } from 'react'
import styles from './Header.module.scss'
import { Link, NavLink, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const logo = require('../../assets/alumag-logo.png').default

const links = [
  { to: '/objekti-sa-nasom-stolarijom', text: 'Objekti sa našom stolarijom' },
  { to: '/o-nama', text: 'O nama' },
  { to: '/karakteristike-alu-stolarije', text: 'Karakteristike alu-stolarije' },
  { to: '/karakteristike-PVC-stolarije', text: 'Karakteristike PVC stolarije' },
]

const Header = () => {
  const [headerClass, setHeaderClass] = useState('')
  const [navOpen, setNavOpen] = useState('')
  const { pathname } = useLocation()

  const handleScroll = useCallback(() => {
    const scrollTop =
      window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop

    if (scrollTop > 1) {
      setHeaderClass(styles.Header)
    } else {
      setHeaderClass(`${styles.Header} ${styles['Header--transparent']}`)
    }
  }, [])

  const handleDocumentClick = useCallback(event => {
    if (!event.target.closest('.keep-open')) {
      setNavOpen('')
    }
  }, [])

  useEffect(() => {
    if (pathname === '/') {
      document.addEventListener('scroll', handleScroll)
      setHeaderClass(`${styles.Header} ${styles['Header--transparent']}`)
    } else {
      document.removeEventListener('scroll', handleScroll)
      setHeaderClass(styles.Header)
    }
  }, [pathname, handleScroll])

  useEffect(() => {
    if (navOpen) {
      document.addEventListener('click', handleDocumentClick)
    } else {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [navOpen, handleDocumentClick])

  return (
    <header className={headerClass}>
      <Link className={styles['Header__image-wrapper']} to="/">
        <img className={styles['Header__image-wrapper__image']} src={logo} alt='logo' />
      </Link>
      <FontAwesomeIcon
        className={`${styles.Header__bars} keep-open`}
        icon={faBars}
        onClick={() => setNavOpen(styles['Header__navigation--open'])}
      />
      <nav className={`${styles.Header__navigation} ${navOpen} keep-open`}>
        <div className={styles.Header__navigation__close}>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setNavOpen('')}
          />
        </div>
        {links.map(({ to, text }) => (
          <NavLink
            className={styles.Header__navigation__link}
            activeClassName={styles['Header__navigation__link--active']}
            to={to}
            onClick={() => setNavOpen('')}
            key={to}
          >
            {text}
          </NavLink>
        ))}
      </nav>
    </header>
  )
}

export default Header
