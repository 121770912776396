import React from 'react'
import styles from './AboutUs.module.scss'
import TextBlock from '../../components/TextBlock/TextBlock'

const AboutUs = () => (
  <div className={styles.AboutUs}>
    <TextBlock
      title={<h2><strong>O nama</strong></h2>}
      text={
        <>
          <p>
            Preduzeće Alumag-Plast posluje od 1996. na teritoriji cele Srbije kao i van
            granica naše zemlje. Registrovani smo kao preduzeće koje se bavi izradom i
            ugradnjom Alu i PVC stolarije, strukturnih fasada, Alu bond panela, aluminijumskih
            ograda, roletni i komarnika.
          </p>
          <p>
            Kontinuitet našeg poslovanja obezbedili smo visokim kvalitetom naših
            proizvoda i strogim poštovanjem rokova izrade i ugradnje.
          </p>
        </>
      }
    />
  </div>
)

export default AboutUs
