import React, { useRef } from 'react'
import styles from './Home.module.scss'
import SwiperCore, { EffectFade, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'
import 'swiper/components/navigation/navigation.scss'
import TextBlock from '../../components/TextBlock/TextBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const image1 = require('../../assets/carousel/carousel-1.jpg').default
const image2 = require('../../assets/carousel/carousel-2.jpg').default
const image3 = require('../../assets/carousel/carousel-3.jpg').default
const image4 = require('../../assets/carousel/carousel-4.jpg').default

SwiperCore.use([EffectFade, Navigation])

const Home = () => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const images = [image1, image2, image3, image4]

  return (
    <>
      <Swiper
        slidesPerView={1}
        effect="fade"
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.update()
        }}
        style={{ height: '100vh' }}
        loop
      >
        <button
          className={`${styles.Home__arrow} ${styles['Home__arrow--left']}`}
          ref={prevRef}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {images.map(image => (
          <SwiperSlide key={image}>
            <div className={styles['Home__slide__image']} style={{ backgroundImage: `url(${image})` }}></div>
          </SwiperSlide>
        ))}
        <button
          className={`${styles.Home__arrow} ${styles['Home__arrow--right']}`}
          ref={nextRef}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </Swiper>
      <TextBlock
        title={<h2>Karakteristike <span className="bold">ClimaGuard solar stakla</span></h2>}
        text={
          <>
            <p>
              Naši proizvodi osim što su napravljeni od <span className="bold">najkvalitetnijih materijala</span> poseduju
              još jednu karakteristiku, to je <span className="bold">zvučna i termo izolacija</span>, a to je sve
              moguće ostvariti ugradnjom ClimaGuard solar stakla.
            </p>
            <p>
              Glavna karakteristika ovog stakla je <span className="bold">vrhunska toplotna
              izolacija</span> koja omogućava veliku uštedu toplotne energije u zimskom periodu.
            </p>
            <p>
              Ova izolacija ClimaGuard solar stakla važna je i za tople letnje dane jer
              poseduje <span className="bold">osobinu niskog prenosa toplote</span>. Ako bi smo poredili ClimaGuard sa
              ostalim staklom uočavamo da obično staklo propušta oko 80% toplotne energije
              što je u letnjem periodu jako neprijatno.
            </p>
            <p>
              Stalni rast cene energenata upućuje na to da je jedan od
              najvažnijih elemenata izolacije izbor odgovarajućih stakala.
            </p>
          </>
        }
        biggerPadding
      />
    </>
  )
}

export default Home
