import React from 'react'
import styles from './AluStolarija.module.scss'
import TextBlock from '../../components/TextBlock/TextBlock'

const AluStolarija = () => (
  <div className={styles.AluStolarija}>
    <TextBlock
      title={<h2>Karakteristike <span className="bold">alu-stolarije</span></h2>}
      text={
        <>
          <p>
            Imajući u vidu visoke standarde današnjeg tržišta, naša
            aluminijumska stolarija u potpunosti zadovoljava sve standarde i zahteve kupaca.
            Aluminijumski profil je <span className="bold">veoma otporan</span> na sve atmosferne uticaje pa se zbog toga i
            smatra trajnom stolarijom.
          </p>
          <p>
            Jedna od osnovnih prednosti alu-stolarije je i <span className="bold">odlična zvučna i
              termo izolacija</span>, tako da može odgovoriti na sve zahteve kupaca.
          </p>
          <p>
            Pored toga što je dugotrajna, aluminijumska stolarija <span className="bold">prednjači i
              u estetskom izgledu</span> svojih profila.
          </p>
        </>
      }
    />
  </div>
)

export default AluStolarija
