import React from 'react'
import styles from './PVCStolarija.module.scss'
import TextBlock from '../../components/TextBlock/TextBlock'

const PVCStolarija = () => (
  <div className={styles.PVCStolarija}>
    <TextBlock
      title={<h2>Karakteristike <span className="bold">PVC stolarije</span></h2>}
      text={
        <>
          <p>
            PVC profil je zbog svojih karakteristika <span className="bold">jedan od najprodavanijih materijala u svetu.</span>
          </p>
          <p>
            Njegove glavne karakteristike su <span className="bold">otpornost na svetlost i druge atmorsferske
              uticaje kao i na hemikalije</span> zbog čega ovaj profil nalazi široku primenu u građevinarstvu.
          </p>
        </>
      }
    />
  </div>
)

export default PVCStolarija
