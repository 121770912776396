import React from 'react'
import styles from './Gallery.module.scss'
import './ImageGallery.scss'
import ImageGallery from 'react-image-gallery'

const Gallery = ({ images }) => {
  return (
    <div className={styles.Gallery}>
      <ImageGallery items={images} lazyLoad />
    </div>
  )
}

export default Gallery
