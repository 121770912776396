import React  from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Header from './components/Header/Header'
import Home from './pages/Home/Home'
import Gallery from './pages/Gallery/Gallery'
import AluStolarija from './pages/AluStolarija/AluStolarija'
import PVCStolarija from './pages/PVCStolarija/PVCStolarija'
import AboutUs from './pages/AboutUs/AboutUs'

const importAll = r => {
  return r.keys().map(r).map(el => ({ original: el.default, thumbnail: el.default }))
}

const galleryRoutes = [
  {
    route: '/objekti-sa-nasom-stolarijom',
    images: importAll(require.context(`./assets/objekti-sa-nasom-stolarijom`, false))
  }
]

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path="/karakteristike-alu-stolarije">
          <AluStolarija />
        </Route>
        <Route path="/karakteristike-PVC-stolarije">
          <PVCStolarija />
        </Route>
        {galleryRoutes.map(({ route, images }) => (
          <Route path={route} key={route}>
            <Gallery images={images} />
          </Route>
        ))}
        <Route path="/o-nama">
          <AboutUs />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
