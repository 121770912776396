import React from 'react'
import styles from './TextBlock.module.scss'

const TextBlock = ({ title, text, darker, biggerPadding }) => (
  <div className={
        `${styles.TextBlock}
        ${darker ? styles['TextBlock--darker'] : ''}
        ${biggerPadding ? styles['TextBlock--bigger-padding'] : ''}`
      }
  >
    <div className={styles.TextBlock__title}>{title}</div>
    <div className={styles.TextBlock__text}>
      {text}
    </div>
  </div>
)

export default TextBlock
